import styles from "./mediaPool.module.css";
import React, { useState, useEffect } from "react";
import { Media } from "../../model/types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import More from "../../img/more.svg";
import { useVideoContext } from "../../provider/VideoProvider";
const options = {
  types: [
    {
      accept: {
        "videos/*": [".mp4", ".mov", ".wmv", ".avi", ".flv"],
        "images/*": [".jpg", ".png", ".gif", ".jpeg"],
      },
    },
  ],
  multiple: true,
  excludeAcceptAllOption: true,
};

export default function MediaPool(props: any) {
  const [status, setStatus] = useState<string>("");
  const [draggedOn, setDraggedOn] = useState<String>("");
  const [popup, setPopup] = useState<number>(-1);

  const { mediaurul, setGetURl } = useVideoContext();
  // const myFun = props.addVideo;
  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLButtonElement;
    if (target)
      if (target.getAttribute("data-id") === "more_img") {
        return;
      }
    setPopup(-1);
  };
  function onclickimage(index: number) {
    props.setcurrentIndex(index);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const listItems = props.mediaList.map((item: Media, index: number) => {
    return (
      <Draggable
        key={index.toString()}
        draggableId={index.toString()}
        index={index}
      >
        {(provided) => (
          <li
            className={`${styles.card} `}
            key={index}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <img
              className={styles.img}
              src={item.thumbnail}
              alt={item.file.name}
              onClick={() => {
                onclickimage(index);
              }}
            />
            <img
              className={styles.more}
              src={More}
              alt={item.file.name}
              data-id="more_img"
              onClick={(e) => {
                setPopup(index);
              }}
            />
            <div
              className={
                popup === index
                  ? styles.popup + " " + styles.control
                  : styles.control
              }
            >
              <div className={styles.arrowGroup}>
                <span className={`material-symbols-outlined ${styles.west}`}>
                  west
                </span>
                <span className={`material-symbols-outlined ${styles.east}`}>
                  east
                </span>
              </div>
              <div
                className={styles.control_btn}
                onMouseDown={() => { props.setcurrentIndex(index); props.setShowEditorModal(true) }}
              >
                <span
                  className={`material-symbols-outlined ${styles.control_icon}`}
                >
                  edit
                </span>
                Edit Scene
              </div>
              <div
                className={styles.control_btn}
                onMouseDown={() => props.deleteVideo(item)}
              >
                <span
                  className={`material-symbols-outlined ${styles.control_icon}`}
                >
                  delete
                </span>
                Delete
              </div>
              <div className={styles.control_btn}>
                <span
                  className={`material-symbols-outlined ${styles.control_icon}`}
                >
                  content_copy
                </span>
                Duplicate
              </div>
              <div
                className={styles.control_btn}
                onMouseDown={() => props.setShowAddModal(true)}
              >
                <span
                  className={`material-symbols-outlined ${styles.control_icon}`}
                >
                  add
                </span>
                Add a scene
              </div>
            </div>
            <div className={styles.length}>00:07</div>
          </li>
        )}
      </Draggable>
    );
  });

  const onClick = async () => {
    try {
      const files: File[] = [];
      //@ts-ignore
      const Handle = await window.showOpenFilePicker(options);
      setStatus("Loading...");
      for (const entry of Handle) {
        let file = await entry.getFile();
        files.push(file);
      }
      await props.addVideo(files);
      setStatus("");
    } catch (error) {
      console.log(error);
    }
  };

  const onDrag = async (e: React.DragEvent<HTMLDivElement>) => {

    e.preventDefault();
    e.stopPropagation();
    setDraggedOn("");
    if (!e.dataTransfer) return;
    const files: File[] = [];

    for (const item of Object.values(e.dataTransfer.items)) {
      const file = item.getAsFile();

      if (
        file !== null &&
        (file.type.includes("video/") || file.type.includes("image/"))
      )
        files.push(file);
      else
        alert(
          `Could not upload file: ${file?.name}. Only upload videos or images.`
        );
    }
    await props.addVideo(files);
    setStatus("");
  };

  return (
    <div
      onDragOver={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setDraggedOn("draggedOn");
      }}
      onDragEnter={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setDraggedOn("draggedOn");
      }}
      onDragLeave={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setDraggedOn("");
      }}
      onDrop={onDrag}
      className={`${styles.container} ${draggedOn}`}
    >
      <div className={styles.hbox} onClick={onClick} title="Add files">
        <span className="material-symbols-outlined md-36">add</span>
      </div>
      {props.mediaList.length > 0 && (
        <>
          <div className={styles.left_arrow}>
            <span className="material-symbols-outlined md-36">
              navigate_before
            </span>
          </div>
          <div className={styles.right_arrow}>
            <span className="material-symbols-outlined md-36">
              navigate_next
            </span>
          </div>
        </>
      )}

      <div className={styles.mediaList}>
        <Droppable droppableId="card" type="COLUMN" direction="horizontal">
          {(provided) => (
            <ul
              className={`${styles.ul} card`}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listItems}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>

      <p className={styles.loader}>{status}</p>
    </div>
  );
}
