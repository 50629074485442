import { Context, createContext, useContext, useState } from "react";
import { Media, Segment } from "../model/types";
interface VideoContextType {
  mediaList: Media[];
  setMediaList: any;
  trackList: Segment[][];
  setTrackList: any;
  mediaurul: string[],
  setGetURl: any;
  descriptionurls: string[][];
  setDescriptoinUrls: any;
  createstate1:number,
  setCreatestate:any;
  chatHistory:string[],
  setChatHistory:any;
}

export const VideoContext: Context<VideoContextType> =
  createContext<VideoContextType>({
    mediaList: [],
    setMediaList: () => { },
    trackList: [[]],
    setTrackList: () => { },
    mediaurul: [],
    setGetURl: () => { },
    descriptionurls: [[]],
    setDescriptoinUrls: () => { },
    createstate1:0,
    setCreatestate:()=>{ },
    chatHistory:[],
    setChatHistory:()=>{ },
  });

export const useVideoContext = () => useContext(VideoContext);
