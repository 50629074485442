import "./App.css";
import ProjectManager from "./model/projectManager";
import React, { useState } from "react";
import { Media, Segment } from "./model/types";
import { VideoContext } from "./provider/VideoProvider";

const VideoProvider: React.FC = ({ children }) => {
  const [mediaList, setMediaList] = useState<Media[]>([]);
  const [trackList, setTrackList] = useState<Segment[][]>([[]]);
  const [mediaurul, setGetURl] = useState<string[]>([]);
  const [descriptionurls, setDescriptoinUrls] = useState<string[][]>([[]]);
  const [createstate1, setCreatestate] = useState<number>(0);
  const [chatHistory, setChatHistory] = useState<string[]>([]);
  return (
    <VideoContext.Provider
      value={{ mediaList, setMediaList, trackList, setTrackList, mediaurul, setGetURl, descriptionurls, setDescriptoinUrls, createstate1, setCreatestate, chatHistory, setChatHistory }}
    >
      {children}
    </VideoContext.Provider>
  );
};

function App() {
  return (
    <VideoProvider>
      <ProjectManager />
    </VideoProvider>
  );
}

export default App;
